import * as React from "react"
import Helmet from 'react-helmet'

import Layout from '../components/layout.js'
import Contact from '../components/contact.js'


const ServicePage = () => {
  return (
    <Layout>

      <Helmet>
        <title>Freelance Web Developer Brighton | Billy Dawson</title>
        <meta property="description" content="Using modern tools and development techniques I create, maintain and alter all kinds of websites from simple brochures to e-commerce." />
      </Helmet>

      <div className="big-header yellow">
        <div className="wrap container">
          <div className="row">
            <div className="col-xs-11 col-sm-8 col-md-6 col-lg-5">
              <h1>Freelance Web Developer Brighton</h1>
              <p>I create bespoke, lightweight websites from the ground up based on your businesses needs.</p>
              <p className="muted">Using modern tools to create minimal, effective code the sites I build have vastly increased performance both in site speed and search engines.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="wrap container">
          <div className="row title-row">
            <div className="col-xs-12">
              <h2>Why choose a bespoke website?</h2>
            </div>
          </div>

          <div className="row list-items">

            <div className="col-xs-12 col-md-4">
              <p>Get exactly the features you need and leave room to expand in future.</p>
              <p className="muted">Finding a theme or out-of-the-box website with all of the specific features you need can be extremely difficult. I can help you find out what you need and get it built.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Display your brand exactly how you want it to be displayed.</p>
              <p className="muted">Nothing ties together a brand like a professional website. I can work with you or your designer to build something that represents your brand just as it should.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Get a fast and responsive website – without all the heavy plugins.</p>
              <p className="muted">Most out-of-the-box websites have to cover every possible use case, this means they normally end up with far too much code for your needs.</p>
            </div>

          </div>
        </div>
      </div>

      <div className="section">
        <div className="wrap container">
          <div className="row title-row">
            <div className="col-xs-12">
              <h2>How I can help with bespoke development</h2>
            </div>
          </div>

          <div className="row list-items">

            <div className="col-xs-12 col-md-4">
              <p>Designing and building a new website from the ground up.</p>
              <p className="muted">If you’re looking for a new website and don’t know the next steps, I can walk you through the process from ideation → design → launch.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Making alterations to an existing website.</p>
              <p className="muted">If you’ve already got a website you love but you could do with a few odds and ends fixing or new features I’m more than happy to get them sorted.</p>
            </div>

            <div className="col-xs-12 col-md-4">
              <p>Developing your website from designs you already have.</p>
              <p className="muted">If you’ve got a design in mind, or already completed, I can make that into a functioning website with all of the features you need directly from the design files.</p>
            </div>

          </div>
        </div>
      </div>

      <Contact colour="green" />

    </Layout>
  )
}

export default ServicePage
